var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        "filter-method": (val) => _vm.handleSearchMpaReceivingVessels(val),
        filterable: "",
        "popper-class": "custom-ship-selector",
        disabled: _vm.disabled,
        loading: _vm.searchLoading,
        placeholder: "Select",
      },
      on: {
        "visible-change": _vm.searchVesselvisibleChange,
        change: function ($event) {
          return _vm.$emit("handleSelectVessel", $event)
        },
      },
      model: {
        value: _vm.vesselSearchKey,
        callback: function ($$v) {
          _vm.vesselSearchKey = $$v
        },
        expression: "vesselSearchKey",
      },
    },
    [
      _c("div", { staticClass: "header" }, [
        _c("span", [_vm._v("Name")]),
        _c("span", [_vm._v("IMO")]),
        _c("span", [_vm._v("Licence")]),
      ]),
      _vm._l(_vm.options, function (item) {
        return _c(
          "el-option",
          { key: item.id, attrs: { label: item.shipName, value: item.id } },
          [
            _c("div", { staticClass: "body" }, [
              _c("span", [_vm._v(_vm._s(item.shipName) + " ")]),
              _c("span", [_vm._v(_vm._s(item.imo))]),
              _c("span", [_vm._v(_vm._s(item.licenceNo))]),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }