<template>
  <el-form :label-position="'top'" :model="financeRequestForm" ref="reqestForm">
    <div>
      <div  class="sub-module-title" style="padding:0">① Documents</div>
      <financer-file-upload
        v-bind="$attrs"
        title="Invoice"
        fieldName="requestInvoiceDocuments"
        :isMultiple="false"
        :allowEdit="allowEdit"
        :disabledUploadOrDeleteFiles="disabledUploadRequestFiles"
        :uploadFiles="financeRequestForm.requestInvoiceDocuments"
        @setUploadFiles="setUploadFiles"
        @setRemarksEditFlag="$emit('setRemarksEditFlag')"
      />
      <financer-file-upload
        v-bind="$attrs"
        title="EBDN"
        fieldName="ebdnDocuments"
        :isMultiple="true"
        :allowEdit="allowEdit"
        :disabledUploadOrDeleteFiles="disabledUploadRequestFiles"
        :uploadFiles="financeRequestForm.ebdnDocuments"
        @setUploadFiles="setUploadFiles"
        @setRemarksEditFlag="$emit('setRemarksEditFlag')"
      />
      <financer-file-upload
        v-bind="$attrs"
        title="Order"
        fieldName="orderDocuments"
        :isMultiple="true"
        :allowEdit="allowEdit"
        :disabledUploadOrDeleteFiles="disabledUploadRequestFiles"
        :uploadFiles="financeRequestForm.orderDocuments"
        @setUploadFiles="setUploadFiles"
        @setRemarksEditFlag="$emit('setRemarksEditFlag')"
      />
      <div class="sub-module-title" style="padding:20px 0px 15px 0px">② Financing Information</div>
      <div class="div-frac-14-avg" >
        <el-form-item label="Reference Number" prop="referenceNo"  :rules="rules.notReqRule">
          <el-input
            v-model="financeRequestForm.referenceNo"
            :disabled="disabledRequestForm"
          />
        </el-form-item>
        <el-form-item label="Delivery Date" prop="deliveryDate"  :rules="rules.reqRule">
          <el-date-picker v-model="financeRequestForm.deliveryDate" :disabled="disabledRequestForm"/>
        </el-form-item>
        <el-form-item label="Amount" prop="amount" :rules="rules.reqGreaterThanZeroRule">
          <el-input-number
          v-model="financeRequestForm.amount"
          :controls="false"
          :precision="3" :min="0"
          :disabled="disabledRequestForm"
          @change="$emit('onCalculateSummary',financeRequestForm)"
          >
          </el-input-number>
        </el-form-item>
        <el-form-item label="With Recourse" prop="withRecourse"  :rules="rules.reqRule">
          <el-select v-model="financeRequestForm.withRecourse" :disabled="disabledRequestForm">
            <el-option v-for="item in WITH_RECOURSE_OPTIONS" :key="item.name" :label="item.name" :value="item.value" />
          </el-select>
        </el-form-item>
      </div>
      <div class="div-frac-12-spec">
        <el-form-item label="Payment Days" prop="paymentDays"  :rules="rules.reqGreaterThanZeroRule">
          <el-input
          type="number"
          v-model.number="financeRequestForm.paymentDays"
          :disabled="disabledRequestForm"
          @change="$emit('onCalculateSummary',financeRequestForm)"
          placeholder="Please input number" >
          <template slot="append">day</template>
          </el-input>
        </el-form-item>
        <el-form-item label="Remarks" prop="remarks"  :rules="rules.notReqRule">
          <el-input
            type="textarea"
            :rows="1"
            v-model="financeRequestForm.remarks"
            :disabled="disabledRequestForm"
          />
        </el-form-item>
      </div>
      <div class="sub-module-title" style="padding:20px 0px 15px 0px">③ Account Receivable</div>
      <div class="div-frac-14-avg" >
      <el-form-item label="Company Name" prop="companyName"  :rules="rules.reqRule">
        <el-select
          :loading="companySelectLoading"
          allow-create
          filterable
          v-model="financeRequestForm.companyName"
          :disabled="disabledRequestForm"
          @change="handleChangePayableCompanyName"
          >
          <el-option
            v-for="item in $_.uniqBy(companyOptionList,'companyName')"
            :key="item.companyName" :label="item.companyName.toUpperCase()" :value="item.companyName.toUpperCase()"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Company Address" prop="companyAddress"  :rules="rules.notReqRule">
          <el-input
            v-model="financeRequestForm.companyAddress"
            :disabled="disabledRequestForm"
            @change="handleChangePayableInfo"
          />
        </el-form-item>
        <el-form-item label="Person In Charge" prop="personInChargeName"  :rules="rules.notReqRule">
          <el-input
            v-model="financeRequestForm.personInChargeName"
            :disabled="disabledRequestForm"
            @change="handleChangePayableInfo"
          />
        </el-form-item>
       <el-form-item label="Contact Number" prop="contactNumber"  :rules="rules.notReqRule">
          <el-input
            v-model="financeRequestForm.contactNumber"
            :disabled="disabledRequestForm"
            @change="handleChangePayableInfo"
          />
        </el-form-item>
        </div>
      <el-form-item label="Email" prop="emails" :rules="[{ required: true, validator:this.checkEmailsValidation, trigger: ['blur'] }]">
        <el-input
          type="textarea"
          :rows="2"
          v-model="financeRequestForm.emails"
          :disabled="disabledRequestForm || !financeRequestForm.companyName"
          @change="handleChangePayableInfo"
        />
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import { mapState } from 'vuex'
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'
import FinancerFileUpload from './FinancerFileUpload.vue'
import { createRequestDocuments, createOrUpdateAccountPayable, getAccountPayableList } from '@/services/modules/finance.js'
import { FINANCE_VIEW, FINANCE_STATUS } from '@/constants.js'
import { sortByAlphabetical } from '@/utils'
export default {
  name: 'RequestInfoForm',
  components: { FinancerFileUpload },
  mixins: [baseMixin, baseTableMixin],
  props: {
    nftFormData: Object
  },
  data () {
    const rules = {
      reqRule: [{ required: true, message: 'This field is required', trigger: ['blur', 'change'] }],
      notReqRule: [{ required: false }],
      reqGreaterThanZeroRule: [{ required: true, validator: this.reqGreaterThanZeroValidation, trigger: ['blur'] }],
      reqEmail: [
        { required: true, message: 'Please input email address', trigger: 'blur' },
        { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
      ]
    }
    const WITH_RECOURSE_OPTIONS = [
      { value: true, name: 'YES' },
      { value: false, name: 'NO' }
    ]
    return {
      rules,
      WITH_RECOURSE_OPTIONS,
      FINANCE_STATUS,
      FINANCE_VIEW,
      financeRequestForm: {
        ebdnDocuments: [],
        orderDocuments: [],
        requestInvoiceDocuments: [],
        referenceNo: '',
        deliveryDate: null,
        withRecourse: false,
        amount: 0,
        paymentDays: null,
        remarks: '',
        emails: '',
        companyName: '',
        personInChargeName: '',
        companyAddress: '',
        contactNumber: '',
        accountPayable: {
          companyName: '',
          personInChargeName: '',
          companyAddress: '',
          contactNumber: '',
          emails: ''
        },
        accountPayableId: ''
      },
      companySelectLoading: false,
      isCreateNewPayableAccount: false,
      isUpdatePayableAccount: false,
      pendingFinanciers: null,
      accountPayableList: [],
      companyOptionList: []
    }
  },
  computed: {
    ...mapState(['currentCompany', 'userExt', 'creditAssociationsCompanyList']),
    disabledRequestForm () {
      return this.$attrs.isFinancier || [FINANCE_STATUS[1], FINANCE_STATUS[2], FINANCE_STATUS[3], FINANCE_STATUS[4]].includes(this.$attrs.status)
    },
    disabledUploadRequestFiles () {
      return this.$attrs.isFinancier || [FINANCE_STATUS[0], FINANCE_STATUS[1], FINANCE_STATUS[2], FINANCE_STATUS[3], FINANCE_STATUS[4]].includes(this.$attrs.status)
    },
    allowEdit () {
      return [this.FINANCE_VIEW.EDIT_FINANCE, this.FINANCE_VIEW.CREATE_FINANCE].includes(this.$attrs.view) && FINANCE_STATUS[4] !== this.$attrs.status && !this.$attrs.isFinancier
    }
  },
  created () {
    if (this.nftFormData) {
      const reqFormNameKeys = Object.keys(this.financeRequestForm)
      const propsdata = { ...this.$_.pick(this.nftFormData, reqFormNameKeys) }
      this.financeRequestForm = propsdata
      this.financeRequestForm.companyAddress = this.nftFormData.accountPayable?.companyAddress ?? ''
      this.financeRequestForm.personInChargeName = this.nftFormData.accountPayable?.personInChargeName ?? ''
      this.financeRequestForm.contactNumber = this.nftFormData.accountPayable?.contactNumber ?? ''
    }
    if (!this.$attrs.isFinancier) {
      this.reqGetAccountPayableList()
    }
  },
  methods: {
    sortByAlphabetical,
    checkEmailsValidation (rule, value, callback) {
      const regex = /^[A-Za-z\d]+([+-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
      const emails = value.replace(/\s*/g, '').split(';').filter(Boolean)
      if (emails.length > 0) {
        emails.forEach(email => {
          if (!regex.test(email)) {
            callback(new Error('Please enter valid email addresses.'))
          }
        })
      } else {
        callback(new Error('Please enter valid email addresses.'))
      }
      callback()
    },
    resetValue (fieldName, value) {
      this.$set(this.financeRequestForm, fieldName, value)
    },
    reqGreaterThanZeroValidation (rule, value, callback) {
      if ((value === 0)) {
        callback(new Error('Value must be greater than 0'))
      } else if (!value) {
        callback(new Error('This field is required'))
      } else {
        callback()
      }
    },
    setUploadFiles (list, fieldName) {
      this.financeRequestForm[fieldName] = list
    },
    reqGetAccountPayableList (cb) {
      this.companySelectLoading = true
      getAccountPayableList().then(resp => {
        this.companySelectLoading = false
        if (resp?.code === 1000) {
          this.accountPayableList = resp.data.content
          const mergeList = [...this.accountPayableList, ...this.creditAssociationsCompanyList]
          this.companyOptionList = ((mergeList.length > 0) ? mergeList.map(cp => { return { companyName: (cp?.counterpartyCompanyName ?? cp.companyName).toUpperCase(), emails: cp?.emails ?? null, id: cp?.id ?? null } }) : [])
          cb && cb()
        } else {
          // this.$message({
          //   type: 'error',
          //   message: 'Get data failed!'
          // })
        }
      })
    },
    reqCreateRequestDocuments (payload) {
      createRequestDocuments(payload).then(resp => {
        if (resp.code === 1000) {
          this.$emit('populateFormData', resp.data.id)
          this.$message({
            type: 'success',
            message: 'Create successful!'
          })
        } else {
          this.$message({
            type: 'error',
            message: `${resp.message}`
          })
        }
      })
    },
    handleChangePayableCompanyName (selectedCompanyName) {
      this.resetValue('emails', '')
      const setAccountPayable = () => {
        const index = this.accountPayableList.findIndex(ap => ap.companyName === selectedCompanyName)
        if (index !== -1) {
          const accountPayable = this.accountPayableList[index]
          this.financeRequestForm.accountPayableId = accountPayable.id
          this.financeRequestForm.companyAddress = accountPayable.companyAddress
          this.financeRequestForm.contactNumber = accountPayable.contactNumber
          this.financeRequestForm.personInChargeName = accountPayable.personInChargeName
          this.financeRequestForm.accountPayableId = accountPayable.id
          let emailsStr = ''
          accountPayable.emails.forEach(item => {
            emailsStr = `${item.email};${emailsStr}`
          })
          // const emailArr = this.accountPayableList[index].emails.map(e => { return e.email })
          this.financeRequestForm.emails = emailsStr
          this.isCreateNewPayableAccount = false
        } else {
          this.isCreateNewPayableAccount = true
        }
      }
      this.reqGetAccountPayableList(setAccountPayable)
    },
    handleChangePayableInfo () {
      if (!this.isCreateNewPayableAccount) {
        this.isUpdatePayableAccount = true
      }
    },
    submitRequestForm (pendingFinanciers) {
      if (this.financeRequestForm.ebdnDocuments.length === 0 || this.financeRequestForm.orderDocuments.length === 0 || this.financeRequestForm.requestInvoiceDocuments.length === 0) {
        this.$message({
          type: 'error',
          message: 'Invoice documents, EBDN documents and  Order documents all need to be uploaded!'
        })
      } else {
        if (pendingFinanciers && pendingFinanciers.length > 0) {
          const financierIds = pendingFinanciers.map(item => item.id)
          this.$refs.reqestForm.validate((valid) => {
            if (valid) {
              // this.financeRequestForm.withRecourse = false
              const payload = {
                ...this.financeRequestForm,
                financierIds
                // withRecourse: (this.financeRequestForm.withRecourse === 0) ? false : true,
              }
              const reqDocListNames = ['requestInvoiceDocuments', 'orderDocuments', 'ebdnDocuments']
              reqDocListNames.forEach(docListName => {
                if (payload[docListName]?.length > 0) {
                  payload[docListName] = payload[docListName].map(item => {
                    return { sysFileId: item.sysFileId, remarks: item.remarks, fileName: item.fileName }
                  })
                }
              })
              if (this.isCreateNewPayableAccount || this.isUpdatePayableAccount) {
                const accountPayableId = this.isUpdatePayableAccount ? this.financeRequestForm.accountPayableId : null
                createOrUpdateAccountPayable(payload, accountPayableId).then(resp => {
                  if (resp.code === 1000 && resp.data.id) {
                    payload.accountPayableId = resp.data.id
                    this.reqCreateRequestDocuments(payload)
                  } else {
                  }
                })
              } else {
                this.reqCreateRequestDocuments(payload)
              }
            } else {
              this.$message({
                type: 'error',
                message: 'Required field can not be null'
              })
              return null
            }
          })
        } else {
          this.$message({
            type: 'error',
            message: 'Please Select Financer'
          })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../index.scss";
.cus-tab-container {
  width: 98%;
  text-align: center;
  display: flex;
  flex-direction: row;
  .tab-item-nav {
    width: 50%;
    color: #4C565C;
    font-size: 14px;
    line-height: 30px;
    border-bottom: 3px solid #DFE1EF;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .notice-pod {
      margin-top: 10px;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius:4px;
      background-color: #FF8E3D;
    }
  }
}
</style>
